import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Switch,Route} from 'react-router-dom';
import AdminLogin from './adminUser/AdminLogin';
import HomePage from './adminUser/HomePage';
import CompanyCreation from './adminUser/CompanyCreation';
import CompanyLogin from './CompanyDetails/CompanyLogin';
import CompanyHome from './CompanyDetails/CompanyHome';
import SmsFileUpload from './CompanyDetails/SmsFileUpload';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Switch>  
      <Route path="/" exact component={AdminLogin} ></Route>
      <Route path="/HomePage" exact component={HomePage} />
      <Route path="/companyCreation" exact component={CompanyCreation} />
      <Route path="/companyLogin/:companyID" exact component={CompanyLogin} />
      <Route path="/CompanyHome" exact component={CompanyHome} />
      <Route path="/smsFileUpload" exact component={SmsFileUpload} />
    </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
