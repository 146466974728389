import React, { useEffect, useReducer, useRef, useState } from 'react';
import './css/ClientLogin.css';
import './css/RegistrationForm.css'
import axios from 'axios';
import { Redirect ,Link,useHistory} from 'react-router-dom';
import titleLogo from './Images/topbatLogo.png';
import capcha from './Images/captcha.png';
import refressCaptch from './Images/refresh.png';
import secondImage from './Images/awardImage.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import SideBar from './sidebar/Sidebar';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copyImage from './Images/link.png';

class HomePage extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

            data:[],
            modelTab : false,
            imageData :null,
            title : "",
            description : "",
            profileImg : document,
            data : [],
            logindIn : true,
            fileSizeCheck : true,
            passShow : false,
            checkFileUpload : false,
            show : false,
            redHome : true,
            companyNameID:'',
            companyId:'',
            editModel:false,
            empMessage:"",
            empdob:"",
            empEmployeeCode:"",
            companyName:"",
            value: '',
            copied: false,
            showDeleteConfirmationModel:false,
            companyDetails:""

        }
    }

    componentDidMount(){

        this.getCompanyDetailsInfo();
       
    }

    getCompanyDetailsInfo = ()=>
    {
        // axios.post('https://api-kiosk.we-matter.com/getCompanyDetails')
        axios.post('https://api-kiosk.we-matter.com/getCompanyDetails')

        .then((response)=>{
            console.log('response ',response.data)

            this.setState({
                data: response.data
            })
        })
        .catch(()=>{
            
        })
    }

    handleModalCustomerTab = (companyNameID,companyId,companyName)=>
    {
        this.setState({
            modelTab: !this.state.modelTab,
            companyNameID:companyNameID,
            companyId:companyId,
            companyName:companyName
        })
    }

    uploadSMSFile = (e) =>
    {
        e.preventDefault();
        if(this.state.imageData != null && this.state.imageData != undefined)
        {
            let formData = new FormData();
            formData.append("uploadFiles",this.state.imageData);
            formData.append("companyNameID",`${this.state.companyName}${this.state.companyId}`);
            formData.append("companyName",this.state.companyName);
            formData.append('companyID',this.state.companyId)

        //   axios.post('https://api-kiosk.we-matter.com/smsFileUpload',formData)
        axios.post('https://api-kiosk.we-matter.com/smsFileUpload',formData)

            .then((response)=>{

            if(response.data === "userCreated")
            {
                toast.success('🦄 User Created Successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
                    //this.findUpdatedFile();
            }
            else
            { 
                toast.error('🦄 File Not Uploded !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
            }
    
    
            }).catch(function (error){
    
                alert(error);
            })
        }
        else
        {
            toast.error('🦄 Please select file !!!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
       

    }

    imageHandler = e => {

        const reader = new FileReader();
        if(e.target.files[0] !=null)
        {
            reader.readAsDataURL(e.target.files[0]);

            this.state.imageData = e.target.files[0];
        }

    };

    handleEditModel = (info) =>
    {
        this.setState({
            editModel:!this.state.editModel,
            companyId:info?.companyId,
            empMessage:info?.companyMessage,
            companyName:info?.companyName
        })
    }

    handleEditSubmit = () =>
    {
        const payload = {
            companyId : this.state.companyId,
            CompanyName : this.state.companyName,
            companyMessage : this.state.empMessage
        }
        // axios.post('https://api-kiosk.we-matter.com/employeeInfoUpdate',payload)
        axios.post('https://api-kiosk.we-matter.com/employeeInfoUpdate',payload)

        .then((response)=>{
            console.log('response ',response.data)
            if(response.data == 'informationUpdated')
            {
                this.setState({
                    editModel:false
                })
                toast.success('🦄 Updated Successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
            else
            {
                toast.error('🦄 Not updated Successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                this.setState({
                    editModel:false
                })
            }
           
        })
        .catch(()=>{
            
        })
    }

    handleChange = (event)=>
    {
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    handleDeleteModel = (details="")=>
    {
        this.setState({
            showDeleteConfirmationModel:!this.state.showDeleteConfirmationModel,
            companyDetails:details
        })
    }

    handleDeleteCompany = ()=>
    {
        const payload = {
            companyId:this.state.companyDetails.companyId
        }
        // axios.post('https://api-kiosk.we-matter.com/deleteCompanyDetails',payload)
        axios.post('https://api-kiosk.we-matter.com/deleteCompanyDetails',payload)

        .then((response)=>{
            
            if(response.data?.deleteStatus == true)
            {
                this.setState({
                    showDeleteConfirmationModel:false
                })
                this.getCompanyDetailsInfo();
                toast.success(`${response.data?.message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
            else
            {
                toast.error('🦄 Not delete Successfully !!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                this.setState({
                    showDeleteConfirmationModel:false
                })
            }
           
        })
        .catch(()=>{
            
        })
    }

   

    render()
    {
        console.log('thistate',this.state.companyDetails)
        return (

            <div>

                <SideBar />

                <div style={{overflow:"scroll"}}>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <td>S.no</td>
                            <td>Company Name</td>
                            <td>Link</td>
                            <td>Edit</td>
                            <td>Upload</td>
                            <td>Delete</td>
                        </tr>
                    </thead>


                    <tbody>
                        {
                            this.state.data.map((item,index)=>(
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.companyName}</td>
                                    <td>
                                <CopyToClipboard text={item.companyLink}>
                                <img src={copyImage} style={{height:"3%",width:"3%",cursor:"pointer"}} />
                                </CopyToClipboard>
                                </td>
                                    <td><button className='btn btn-primary' onClick={()=>{this.handleEditModel(item)}}>Edit</button></td>
                                    <td><button className='btn btn-danger' onClick={()=>{this.handleModalCustomerTab(`${item.companyName}${item.companyId}`,item.companyId,item.companyName)}}>Upload</button></td>
                                    <td><button className='btn btn-danger' onClick={()=>{this.handleDeleteModel(item)}}>Delete</button></td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>

                </div>
               
                <Modal size="md" show={this.state.modelTab} onHide={this.handleModalCustomerTab}>
                        <Modal.Header closeButton>
                            <Modal.Title>File upload</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                       
                        <input type="file" className="btn btn-primary UploadButton" onChange={this.imageHandler}  accept=".xlsm,.xlsx,.xls,.csv" /> <br/>

                        <button className="btn btn-primary UploadButton" onClick={this.uploadSMSFile} >Upload</button>
      

                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleModalCustomerTab} className="modelSaveButton" variant="primary">Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="md" show={this.state.editModel} onHide={this.handleEditModel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Employee Details  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">

                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                        <table className="table">

                                            <tr>
                                                <td>Company Name : </td> <td><input type="text" name="companyName" onChange={this.handleChange} value={this.state.companyName}  /></td>

                                            </tr>

                                            <tr>
                                                <td>Message : </td> <td><input type="text" name="empMessage"  onChange={this.handleChange} value={this.state.empMessage}  /></td>

                                            </tr>

                                        </table>

                                    </div>

                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>

                        <Button onClick={this.handleEditSubmit} className="modelSaveButton" variant="primary">Submit</Button>
                            <Button onClick={this.handleEditModel} className="modelSaveButton" variant="primary">Close</Button>
                        </Modal.Footer>
                    </Modal>


                    {/* Delete Record*/}


                    <Modal size="md" show={this.state.showDeleteConfirmationModel} onHide={this.handleDeleteModel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                       
                            <p>If you want to Delete? Press Yes</p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleDeleteModel} className="modelSaveButton" variant="primary">Close</Button>
                            <Button onClick={this.handleDeleteCompany} className="modelSaveButton" variant="success">Yes</Button>
                        </Modal.Footer>

                    </Modal>



                    <ToastContainer />
            </div>
        )
    }
}


export default HomePage;