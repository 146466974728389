import React, { useEffect, useReducer, useRef, useState } from 'react';

import axios from 'axios';
import { Redirect ,Link,useHistory} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import SideBar from './sidebar/Sidebar';


class CompanyHome extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

            loginId : true,
            data : [],
            message : "",
            link : "",
            companyID:"",
            loginStatus:false

        }

        let token  = sessionStorage.getItem("empID");

        if(token == null || token == "" || token == undefined)
        {
            this.state.loginId = false;
        }
    }

    componentDidMount()
    {
        this.setState({companyID:sessionStorage.getItem("companyID")})
        axios.post('https://api-kiosk.we-matter.com/getEmployeeDetails',{
            empID:sessionStorage.getItem("empID"),
            companyID: sessionStorage.getItem("companyID")
        })
        .then((response)=>{
            console.log('response ',response.data)

            this.setState({
                message:response.data.companyDetails[0].companyMessage,
                link : response.data.empDetails[0].link,
            })

            this.setState({
                data: response.data
            })
        })
        .catch(()=>{
            
        })
    }

    logout = () =>
    {
        sessionStorage.clear();
        setTimeout(()=>{
            console.log('Hello Call')
            this.setState({
                loginStatus:true
            })
        },2000)
       

    }

    render()
    {

        if(this.state.loginId === false)
        {
            return <Redirect to="/companyLogin" />
        }

        
        if(this.state.loginStatus === true)
        {
            return <Redirect to={`/companyLogin/${this.state.companyID}`} />
        }

        
        return (

            <div>

             <SideBar />

             <div className='container-fluid'>
                <div className='row' style={{marginTop:"4%"}}>
                    <div className='col-xl-12'>
                        <h3>{this.state.message}</h3>
                    </div>
                </div>

                {/* <div className='row' style={{marginTop:"10%"}}>
                    <div className='col-xl-12'>
                       <a href={`${this.state.link}`}> <button className='btn btn-primary'>Start Survey</button></a>
                    </div>
                </div> */}

                <div className='row' style={{marginTop:"5%"}}>
                    <div className='col-xl-12'>
                       <a href={`${this.state.link}`} target='_blank' onClick={this.logout}>{this.state.link}</a>
                    </div>
                </div>
             </div>
             
                
            </div>
        )
    }
}


export default CompanyHome;