import React, { useEffect, useReducer, useRef, useState } from 'react';
import './css/ClientLogin.css';
import './css/RegistrationForm.css'
import axios from 'axios';
import Select from 'react-select';
import { Redirect ,Link,useHistory} from 'react-router-dom';
import titleLogo from './Images/topbatLogo.png';
import capcha from './Images/captcha.png';
import refressCaptch from './Images/refresh.png';
import secondImage from './Images/awardImage.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card,Modal} from 'react-bootstrap';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import SideBar from './sidebar/Sidebar';


const allCountry = [

    {name : "Country",value : "India",label : "India"}
]


class CompanyCreation extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

            companyName : "",
            confirmPassword : "",
            captchaCode : "",
            showCaptchCode : "",
            country : "",
            firstName : "",
            link: ""


        }
    }

    handleChnageALl = (event) =>{

		this.setState( {  [event.target.name] : event.target.value } );

	}


    
    handleCaptcha = () =>
    {
       
        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 7;
	    var randomstring = '';

        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        this.setState({showCaptchCode : randomstring});
    }

    componentDidMount()
    {
        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 7;
	    var randomstring = '';

        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        this.setState({showCaptchCode : randomstring});

    }


    

    handleSubmit = (event) =>{
		event.preventDefault();

        let msg  = "";
        let checkError = true;

        if(this.state.captchaCode != this.state.showCaptchCode)
        {
            if(msg == "")
            {
                msg = "🦄 Please Enter Valid Captcha Number !!!";
            }
            checkError = false;
            
        }
        
        if(checkError == true)
        {

            const formData = new FormData();

            formData.append("firstName",this.state.firstName);
            formData.append("companyName",this.state.companyName);
            formData.append('companyMessage',this.state.companyMessage)

            axios.post('https://api-kiosk.we-matter.com/createCompany',formData)
           .then(response => {

                 if(response.data === "insertSuccessfully")
                 {

                    toast.success("Company Created Successfully !!!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    
                        this.setState({

                            companyName : "",
                            firstName : "",
                            link : "",
                            shortLink : "",
                            password : "",
                            confirmPassword : "",
                            companyMessage:""
                        });
                }
                 else
                 {
                  console.log(response.data)  
                     let errorMsg = response.data.sqlMessage.split(" ");
                     toast.error(errorMsg[2]+" already exist !!!", {
                         position: "bottom-center",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                     }
                        
             })
             .catch(error => {
                 console.log(error)
             })
                        
        }
        else
        {

            toast.error(msg, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
           
        }
        
	}





    render()
    {

        const { selectedOption } = this.state;


        return (

            <div>

                <SideBar />
            
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <div className="formDIV card">

                  

                    <form className="formDataFiled"  onSubmit={this.handleSubmit}>

                    <div className="row">

                        <div className="col-xl-12 col-md-12 col-sm-12">
                        <h4 className="h3Data">Create new company </h4>
                        </div>

                    </div>


                    <div className="form-row">
                       
                
                    <div className="form-group col-md-12">
                
                    <input type="text" className="form-control"  placeholder="Company Name"   name="companyName" value={this.state.companyName} onChange={this.handleChnageALl} required />
                    </div>

                    
                    <div className="form-group col-md-12">
                
                    <textarea type="text" className="form-control"  placeholder="Company Message"   name="companyMessage"  onChange={this.handleChnageALl} required >{this.state.companyMessage}</textarea>
                    </div>


                    </div>


                    <div className="form-row">


                    <div className="form-group col-md-6">

                        <img src={capcha} className="captchaImage" />

                        <h4 className="catchaCode">{this.state.showCaptchCode}</h4>

                    </div>


                    <div className="form-group col-md-1">
                    <img src={refressCaptch} className="reFressCaptcha"  onClick={this.handleCaptcha} />
                    </div>

                    <div className="form-group col-md-5">

                    
                    <input type="text" name="captchaCode" value={this.state.captchaCode} onChange={this.handleChnageALl}  required placeholder="Enter CAPTCHA code" className="form-control" autoComplete="off" />


                    </div>



                        <div className="form-group col-md-12">

                        <button type="submit" className="btn btn-primary">Save</button> 

                        </div>


                    </div>


                    </form>  

                        

                    </div>


                    </div>


                </div>

                <ToastContainer />
            </div>




        )
    }
}


export default CompanyCreation;