import React, { useEffect, useReducer, useRef, useState } from "react";
import "../adminUser/css/ClientLogin.css";
import "../adminUser/css/RegistrationForm.css";
import axios from "axios";
import { Redirect, Link, useHistory } from "react-router-dom";
// import {NotificationContainer, NotificationManager} from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
import titleLogo from "../adminUser/Images/topbatLogo.png";
import capcha from "../adminUser/Images/captcha.png";
import refressCaptch from "../adminUser/Images/refresh.png";
import secondImage from "../adminUser/Images/awardImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Modal } from "react-bootstrap";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CompanyLogin = (props) => {
  // const [email ,setEmail] = useState("");
  // const [password , setPassword] = useState("");

  const empID = useRef(null);
  const password = useRef(null);
  const history = useHistory();

  const captchaCode = useRef(null);
  const [showCaptchCode, setShowCaptchCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [show, setShow] = useState(false);
  const [companyID, setCompanyID] = useState(props?.match?.params?.companyID);

  const [employeeCode, setEmployeeCode] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");

  console.log("companyId", companyID);

  const [clientVerify, setClientVerify] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    // if(captchaCode.current.value === showCaptchCode)
    // {
    const passwordInfo = password?.current.value;
    if (
      empID.current.value != null &&
      empID.current.value != "" &&
      ((dateOfBirth != null && dateOfBirth != "") ||
        (passwordInfo != null && passwordInfo != ""))
    ) {
      const tempDate = new Date(dateOfBirth);
      let date = `${tempDate.getDate()}`;
      let month = `${tempDate.getMonth() + 1}`;
      if (date.length == 1) {
        date = `0${date}`;
      }

      if (month.length == 1) {
        month = `0${month}`;
      }

      const dateOfBirthTemp = `${date}-${month}-${tempDate.getFullYear()}`;

      axios
        .post("https://api-kiosk.we-matter.com/companyLogin", {
          //  axios.post('https://clientback.wematter.co.in/ClientLogin',
          //  {
          // axios.post('https://backendsmsinternational.we-matter.xyz/companyLogin',
          //  {
          empID: empID.current.value,
          dateOfBirth: dateOfBirthTemp ? dateOfBirthTemp : "",
          password: passwordInfo ? passwordInfo : "",
          companyId: parseInt(companyID),
        })

        .then((response) => {
          console.log(response.data);
          if (response.data.loginStatus === "loginSuccessfully") {
            sessionStorage.setItem("empID", response.data.empData[0].empCode);
            sessionStorage.setItem("companyID", response.data.companyID);
            history.push("/CompanyHome");
          } else {
            toast.error("🦄 Invalid credentials", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("🦄  Invalid credentials", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
    }

    //  }
    // else
    // {
    //     toast.error('🦄 Please Enter Valid Captcha Code !!', {
    //         position: "top-center",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         });
    //   //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
    // }
  };

  const handleModalPasss = () => {
    setShow(!show);
  };

  const handleClientVerify = () => {
    setClientVerify(!clientVerify);
  };

  const [clientLogo, setClientLogo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api-kiosk.we-matter.com/getCompanyImage/${companyID}`
        );

        // Assuming the response data is in the `data` property
        setClientLogo(
          response?.data && response?.data?.[0] && response?.data?.[0]?.imageUrl
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if needed
      }
    };

    fetchData();
  }, [companyID]);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(
          `https://api-kiosk.we-matter.com/getInputValues/${companyID}`
        );

        if (response.data.length > 0) {
          setEmployeeCode(response.data[0].employeeCode);
          setYearOfBirth(response.data[0].yearOfBirth);
        } else {
          // Set default values if the API response is empty
          setEmployeeCode("Employee Code");
          setYearOfBirth("Year Of Birth");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData1();
  }, [companyID]);
  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();

    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 7;
    var randomstring = "";

    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    setShowCaptchCode(randomstring);
  }, []);

  const handleCaptcha = () => {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 7;
    var randomstring = "";

    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    setShowCaptchCode(randomstring);
  };

  const handleDOB = (event) => {
    setDateOfBirth(event.target.value);
  };

  console.log(clientLogo, "clientLogo");
  console.log("object", companyID === 16);
  console.log("actual", companyID === "16");
  return (
    <div>
      <div className="header">
        <div className="header-img-div">
          <img src={titleLogo} className="titleLogo" />
          <img src={clientLogo} className="titleLogo" />
        </div>
      </div>
      <div className="login-card-div">
        <div className="login card">
          <h1>Company Login</h1>
          <form onSubmit={submitForm}>
            <input
              type="text"
              className="form-control col-sm-12"
              ref={empID}
              placeholder={employeeCode || "Employee Code"}
              id="empID"
              required
            />
            <br />
            <input
              type="password"
              className="form-control col-sm-12"
              ref={password}
              placeholder={yearOfBirth || "Year Of Birth"}
              id="password"
            />

            {/* <DatePicker 
        className="form-control col-sm-12" 
        selected={dateOfBirth} 
        onChange={handleDOB}
        dateFormat="DD/MM/YYYY" /> */}

            {/* <input type="date" className="form-control col-sm-12"  value ={dateOfBirth} onChange={handleDOB}  />

        <p>OR</p> */}

            <br />
            <div className="row"></div>
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <input
                  type="submit"
                  name="commit"
                  value="Generate Link"
                  className="btn btn-primary pl-5 pr-5"
                />
              </div>
            </div>

            <br />
            <br />
          </form>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CompanyLogin;
